import { createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  viewBox: "0 0 15 16",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock("svg", _hoisted_1, [
    _createVNode("path", {
      d: "M7.5 1.5C6.21442 1.5 4.95771 1.88122 3.88879 2.59545C2.81987 3.30968 1.98675 4.32484 1.49478 5.51256C1.00281 6.70028 0.874092 8.00721 1.1249 9.26809C1.3757 10.529 1.99476 11.6872 2.90381 12.5962C3.81285 13.5052 4.97104 14.1243 6.23191 14.3751C7.49279 14.6259 8.79972 14.4972 9.98744 14.0052C11.1752 13.5132 12.1903 12.6801 12.9046 11.6112C13.6188 10.5423 14 9.28558 14 8",
      stroke: _ctx.color,
      "stroke-width": "2",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }, null, 8, ["stroke"])
  ]))
}