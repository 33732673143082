
import { Options, prop, Vue } from "vue-class-component";
import ProgressSpinner from "primevue/progressspinner";

class Props {
  color = prop<string>({
    default: "white",
    type: String
  });
}
@Options({
  components: {
    ProgressSpinner
  }
})
export default class Spinner extends Vue.with(Props) {}
